.wrapper
  display flex
  flex-direction column
  height 100%

//horizontal-center()

.content
  flex 1 0 auto
  overflow hidden
  & > *
    transition: none

body.body
  background-color #e9e9e9
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
  color: #454545;
  line-height: 2 !important;
  font-size: 1.2em;
  @media (max-width: 360px) 
    background-color #f9f9f9

a:hover
  opacity 0.6

.content
  display: flex
  justify-content: center


.checker__body
    background-color #f9f9f9
    min-height calc(100% - 100px)
    border-radius: 10px
    min-width: 1024px
    width 60vw
    margin: 50px 30px
    margin-top 50px
    padding-bottom 40px

    @media (max-width: 1024px) 
      min-width: 400px
      width 95vw

    @media (max-width: 360px) 
      min-width: 100px
      width 100vw
      margin: 0 0
      border-radius: 0
      padding-bottom 0

.checker__body.inpage
  background-color #fff 
  min-width: unset 

.checker_title
  text-align center
  text-transform none
  line-height: 1.3em;
  letter-spacing normal
  padding 60px 40px
  margin 0
  @media (max-width: 640px) 
    font-size 1.5em
  @media (max-width: 640px) 
    padding 30px 20px

.table__wrapper
  display: flex
  justify-content: center
  transition: none

.table__container 
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows:  3fr;
  gap: 0;
  grid-template-areas: "table__ns__caption table__nsl__caption . ." "table__ns__body table__nsl__body . ." ". . . .";
  min-width 43vw
  // width 90vw
  margin: 0 30px 20px 30px
  grid-gap: 1px
  @media (max-width: 1010px) 
      // min-width: 400px
      width 90vw
  @media (max-width: 640px) 
    grid-template-columns: 100%;
    grid-template-rows:  1fr;
    grid-template-areas: "table__ns__caption" "table__ns__body" "table__nsl__caption" "table__nsl__body";
    // min-width: 10px
    width 100vw
    margin: 0 30px 20px 30px


.table__container__text
  margin: 0 70px 0 70px
  transition: none

img.table 
  width 70%
  display: block;
  margin 30px auto
  cursor: pointer
  @media (max-width: 1010px) 
    width: 30vw
  @media (max-width: 640px) 
    width: 55vw
    
  &:hover
    transform: scale(1.04)
    transition: all .2s ease-in
  &:active
    transform: scale(0.95)
    transition: all .2s ease-in

.table__ns__caption { grid-area: table__ns__caption;}

.table__nsl__caption { grid-area: table__nsl__caption;}

.table__ns__body { grid-area: table__ns__body; }

.table__nsl__body { grid-area: table__nsl__body; }

.table__caption
  padding: 0.5em;
  font-weight: bold;
  text-align center
  background-color: #f2f3f3;
  box-shadow:0 0 0 2px #b6b6b6;

.table__body
    box-shadow:0 0 0 1px #b6b6b6;

.result,
.check-result
  padding 30px 70px 0 70px
  transition: none
  @media (max-width: 640px) 
    font-size 0.85em
    padding 30px 30px 0 30px

.hidden
  display none

.checker_p
  margin 20px 0
  margin: 0px 0px 30px 0px;
  transition: none
  @media (max-width: 1000px) 
    font-size .9em
  @media (max-width: 640px) 
    font-size 1em

.checker
  display flex
  justify-content: center
  // padding 0 30px 30px 30px
  @media (max-width: 360px) 
    padding-bottom: 30px

input[type=text], select {
  max-width: 400px;
  min-width: 150px;
  padding: 12px 20px;
  margin: 0;
  // display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-transform: uppercase;
}

.unknown, .passed, .maybe, .failed {
  display: inline-block;
}

.unknown {
  color: dimgrey;
  font-weight: bolder;
}

.passed {
  color: green;
  font-weight: bolder;
}

.maybe {
  color: goldenrod;
  font-weight: bolder;
}

.failed {
  color: darkred;
  font-weight: bolder;
}

.new_revision
  color:  #7d3c98 ;
  font-weight: bolder;


.remove_margins {
  margins 0
  padding 0
}

a 
  text-decoration: none !important;
  color: #52adc8;
  &:visited 
    color: #7dc2d6;
    &:hover 
      color: #3e8296;

.checker_a:hover, .checker_a:active {
  color: #3e8296;
  outline: 0;
}

.checker_a .checker_img {
	vertical-align: bottom !important;
}

.checker_img {
  width: auto;
  max-width: 80vw;
}

img 
  margin 10px
  width auto
  display: block;
  margin 30px auto
