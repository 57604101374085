.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  flex: 1 0 auto;
  overflow: hidden;
}
.content > * {
  transition: none;
}
body.body {
  background-color: #e9e9e9;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
  color: #454545;
  line-height: 2 !important;
  font-size: 1.2em;
}
@media (max-width: 360px) {
  body.body {
    background-color: #f9f9f9;
  }
}
a:hover {
  opacity: 0.6;
}
.content {
  display: flex;
  justify-content: center;
}
.checker__body {
  background-color: #f9f9f9;
  min-height: calc(100% - 100px);
  border-radius: 10px;
  min-width: 1024px;
  width: 60vw;
  margin: 50px 30px;
  margin-top: 50px;
  padding-bottom: 40px;
}
@media (max-width: 1024px) {
  .checker__body {
    min-width: 400px;
    width: 95vw;
  }
}
@media (max-width: 360px) {
  .checker__body {
    min-width: 100px;
    width: 100vw;
    margin: 0 0;
    border-radius: 0;
    padding-bottom: 0;
  }
}
.checker__body.inpage {
  background-color: #fff;
  min-width: unset;
}
.checker_title {
  text-align: center;
  text-transform: none;
  line-height: 1.3em;
  letter-spacing: normal;
  padding: 60px 40px;
  margin: 0;
}
@media (max-width: 640px) {
  .checker_title {
    font-size: 1.5em;
  }
}
@media (max-width: 640px) {
  .checker_title {
    padding: 30px 20px;
  }
}
.table__wrapper {
  display: flex;
  justify-content: center;
  transition: none;
}
.table__container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 3fr;
  gap: 0;
  grid-template-areas: "table__ns__caption table__nsl__caption . ." "table__ns__body table__nsl__body . ." ". . . .";
  min-width: 43vw;
  margin: 0 30px 20px 30px;
  grid-gap: 1px;
}
@media (max-width: 1010px) {
  .table__container {
    width: 90vw;
  }
}
@media (max-width: 640px) {
  .table__container {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    grid-template-areas: "table__ns__caption" "table__ns__body" "table__nsl__caption" "table__nsl__body";
    width: 100vw;
    margin: 0 30px 20px 30px;
  }
}
.table__container__text {
  margin: 0 70px 0 70px;
  transition: none;
}
img.table {
  width: 70%;
  display: block;
  margin: 30px auto;
  cursor: pointer;
}
@media (max-width: 1010px) {
  img.table {
    width: 30vw;
  }
}
@media (max-width: 640px) {
  img.table {
    width: 55vw;
  }
}
img.table:hover {
  transform: scale(1.04);
  transition: all 0.2s ease-in;
}
img.table:active {
  transform: scale(0.95);
  transition: all 0.2s ease-in;
}
.table__ns__caption {
  grid-area: table__ns__caption;
}
.table__nsl__caption {
  grid-area: table__nsl__caption;
}
.table__ns__body {
  grid-area: table__ns__body;
}
.table__nsl__body {
  grid-area: table__nsl__body;
}
.table__caption {
  padding: 0.5em;
  font-weight: bold;
  text-align: center;
  background-color: #f2f3f3;
  box-shadow: 0 0 0 2px #b6b6b6;
}
.table__body {
  box-shadow: 0 0 0 1px #b6b6b6;
}
.result,
.check-result {
  padding: 30px 70px 0 70px;
  transition: none;
}
@media (max-width: 640px) {
  .result,
  .check-result {
    font-size: 0.85em;
    padding: 30px 30px 0 30px;
  }
}
.hidden {
  display: none;
}
.checker_p {
  margin: 20px 0;
  margin: 0px 0px 30px 0px;
  transition: none;
}
@media (max-width: 1000px) {
  .checker_p {
    font-size: 0.9em;
  }
}
@media (max-width: 640px) {
  .checker_p {
    font-size: 1em;
  }
}
.checker {
  display: flex;
  justify-content: center;
}
@media (max-width: 360px) {
  .checker {
    padding-bottom: 30px;
  }
}
input[type=text],
select {
  max-width: 400px;
  min-width: 150px;
  padding: 12px 20px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-transform: uppercase;
}
.unknown,
.passed,
.maybe,
.failed {
  display: inline-block;
}
.unknown {
  color: #696969;
  font-weight: bolder;
}
.passed {
  color: #008000;
  font-weight: bolder;
}
.maybe {
  color: #daa520;
  font-weight: bolder;
}
.failed {
  color: #8b0000;
  font-weight: bolder;
}
.new_revision {
  color: #7d3c98;
  font-weight: bolder;
}
.remove_margins {
  margins: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  color: #52adc8;
}
a:visited {
  color: #7dc2d6;
}
a:visited:hover {
  color: #3e8296;
}
.checker_a:hover,
.checker_a:active {
  color: #3e8296;
  outline: 0;
}
.checker_a .checker_img {
  vertical-align: bottom !important;
}
.checker_img {
  width: auto;
  max-width: 80vw;
}
img {
  margin: 10px;
  width: auto;
  display: block;
  margin: 30px auto;
}
